
import { AnyAaaaRecord } from 'dns';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import OrganisationPaymentModal from '@/modules/organisation/components/Pricing.vue';
import SignUpButton from '@/modules/marketing/pricing/SignUpButton.vue';
import Action from '../MarketingAction.vue';

@Component({
  components: {
    OrganisationPaymentModal,
    SignUpButton,
    Action,
  },
  computed: {
    ...mapState('content', ['subContent']),
  },
})
export default class MarketingPricing extends Vue {
  showMobileMenu = false;

  subContent!: AnyAaaaRecord;

  async signUp(): Promise<void> {
    await this.$msal.signIn({ state: 'register' });
  }
}
