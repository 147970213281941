<template>
  <Pricing />
</template>

<script>
// @ is an alias to /src

import Pricing from '@/modules/marketing/pricing/MarketingPricing.vue';

export default {
  name: 'pricing',
  components: { Pricing },
  layout: 'default',
  metaInfo: {
    title: 'Plans and Pricing',
    meta: [
      { charset: 'utf-8' },
      {
        name: 'description',
        content: 'Learn about our pricing options. Check-in Buddy has options for any size business.',
      },
    ],
  },
};
</script>
